<template>
  <div class="page form_simple adjust_width">

    <title_cat>Editer ma catégorie</title_cat>

    <el-form label-position="left" label-width="190px" @submit.native.prevent="categorie_edit">

      <el-form-item label="Titre de la catégorie">
        <el-input  type="text"  v-model="name" name="input"></el-input>
      </el-form-item>

      <button>Valider</button>
    </el-form>
    <hr>
    <router-link :to="{ name: 'bo_categorie_delete', params: { categorie_name: $route.params.categorie_name } }" tag="button" class="black">Supprimer</router-link>
  </div>
</template>

<script>
export default {
  name: "produit_add_update",
  data() {
    return {
      name: '',
      // boutique: {},

      categories_move: []
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      document.querySelector('input[name=input]').focus()
    })
    if(this.$route.params.categorie_name)
      this.name = this.$route.params.categorie_name
  },
  methods: {
    categorie_edit() {
      if(this.$route.params.categorie_name && this.name) {
        this.$http.put('/bo/boutique/categorie', { name: this.name, old_name: this.$route.params.categorie_name }).then((r) => {
          console.log(r)
          if (r.status === 200) {
            this.$notify({
              title: 'Catégorie modifié', offset: this.$notifyOffest, type: 'success'
            });
            this.$store.dispatch("boutique_bo/sync");
            this.$router.push({ name: 'bo_produits_in_category', params: { categorie: this.name } })
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>